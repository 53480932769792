.container{
    background: rgb(237, 239, 240);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
.testimonial-card{
    min-height: 400px;
    /* width: 350px; */
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 8px 30px rgba(0,0,0,.3);
    display: flex;
    flex-flow: column;
    justify-content:space-between;
    /* margin-left: 50px; */
   
  }
  .text-0{
    padding: 2.2em;
    line-height: 1.7em;
    position: relative;
    font-size: 13px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
  }


  .text-1{
    padding: 2.2em;
    line-height: 1.7em;
    position: relative;
    font-size: 13px;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace
    
  }

  .text-2{
    padding: 2.2em;
    line-height: 1.7em;
    position: relative;
    font-size: 13px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
  }

  .text-3{
    padding: 2.2em;
    line-height: 1.7em;
    position: relative;
    font-size: 13px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
  }

  .text-4{
    padding: 2.2em;
    line-height: 1.7em;
    position: relative;
    font-size: 15px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
  }

  .text-5{
    padding: 2.2em;
    line-height: 1.7em;
    position: relative;
    font-size: 15px;
    font-family: Helvetica
  }

  .text-6{
    padding: 2.2em;
    line-height: 1.7em;
    position: relative;
    font-size: 15px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
  }

  .text-7{
    padding: 2.2em;
    line-height: 1.7em;
    position: relative;
    font-size: 15px;
    font-family: Georgia, 'Times New Roman', Times, serif
  }

  .text-8{
    padding: 2.2em;
    line-height: 1.7em;
    position: relative;
    font-size: 15px;
    font-family: monospace
  }

  .image{
    background-color:tomato;
    height: 120px;
    width: 120px;
    border-radius: 50%;
    border: 5px solid white;
    position: absolute;
    top: -50%;
    left: 50%;
    transform: translateX(-50%);
    display:flex;
    justify-content:center;
    
  }
  
  .footer{
    background: linear-gradient(90deg, #1e1f1e 0%,  #1e1f1e 100%);
    height: 120px;
    border-radius: 0 0 10px 10px;
    position:relative;
    
  }
  
  .quote{
    font-size: 400%;
    float: right;
    opacity: .1;
    transform: rotate(10deg) translate(-10px, -40px);
   color:#4D3FA3;
  }
  
  .person{
    color:white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
    font-size:1.5rem;
    font-weight: 600;
  }


  /* body .wrapper {
    max-width: 450px;
    margin: 0 auto;
  } */
  body .wrapper h1 {
    margin-top: 50px;
    margin-bottom: 50px;
    /* text-transform: uppercase; */
    font-size: 1.5em;
  }
  body .wrapper .speechbubble {
    background-color: #fff;
    color: #333;
    font-size: .8em;
    line-height: 1.75;
    padding: 15px 25px;
    /* margin-bottom: 75px; */
    cursor: default;
    border: 1px solid #ccc;
    border-radius: 10px;
    transform: rotate(-2deg);
    box-shadow: 5px 5px #1e1f1e;
  }
  body .wrapper .speechbubble:nth-child(2n) {
    border-left: 5px solid;
  }
  body .wrapper .speechbubble:nth-child(2n):after {
    content: '';
    margin-top: -30px;
    padding-top: 0px;
    position: relative;
    bottom: -45px;
    left: 20px;
    border-width: 30px 0 0 30px;
    border-style: solid;
    border-color: #888888 transparent;
    display: block;
    width: 0;
  }
  /* body .wrapper .speechbubble:nth-child(2n+1) {
    border-right: 5px solid;
  } */
  /* body .wrapper .speechbubble:nth-child(2n+1):after {
    content: '';
    margin-top: -30px;
    padding-top: 0px;
    position: relative;
    bottom: -45px;
    left: 210px;
    border-width: 30px 30px 0 0;
    border-style: solid;
    border-color: #1e1f1e transparent;
    display: block;
    width: 0;
  } */
  body .wrapper .speechbubble:nth-child(4n+1) {
    border-color: #1e1f1e;
  }
  body .wrapper .speechbubble:nth-child(4n+2) {
    border-color: #01AD9B;
  }
  body .wrapper .speechbubble:nth-child(4n+3) {
    border-color: #b388dd;
  }
  body .wrapper .speechbubble:nth-child(4n+4) {
    border-color: #ff8750;
  }
  body .wrapper .speechbubble p.content:before {
    content: "“";
    font-family: Georgia;
    font-size: 32px;
    display: inline-block;
    display: -webkit-inline-box;
    line-height: 1;
  }

  p.content:after { 
    content: "\201d";
    font-family: Georgia;
    font-size: 32px;
    line-height: 1;
  }
  
  body .wrapper .speechbubble .username {
    display: inline;
    font-style: italic;
  }
  body .wrapper .speechbubble .username:before {
    content: '- ';
  }