.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.logo-title {
  font-size: 12px;
  margin-left: 0px;
  width: 400px;
  color: #222;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  height: 100px;
  width: 300px;
  vertical-align: center;
}

.logo-copyright {
  height: 100px;
  width: 300px;
  vertical-align: center;
}

.logoTitle {
 text-align: center;
 font-weight: 900;
}

.copyrightText {
  text-align: center;
  font-size: 11px;
 }

.active {
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
  color: #1e1f1e;
}

.activeLink {
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  color: #1e1f1e;

}

li {
  display: inline;
  padding: 10px;
}

/* .loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; 
  animation: spin 2s linear infinite;
} */

/* @keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} */

/* .MuiTypography-body1 {
  font-weight: 600 !important;
} */

.fa-spinner {
  color: #1e1f1e;
}

.loader-container {
  width: 100px;
  margin: 0 auto;
}

.fa-connectdevelop {
  color:#333;
  background: white;
}

span.MuiButton-label a {
  text-decoration: none;
  color: #fff;
}

.MuiTableCell-head {
  font-weight: 700 !important; /*never wanted to do important but no other option 🤷🏽‍*/
}

.add-profile-container {
  margin-bottom: 20px;
}

.MuiTypography-h6 {
  font-size: 16px !important; /*never wanted to do important but no other option 🤷🏽‍*/
  font-weight: 400 !important;
}
.MuiTypography-colorTextSecondary {
  color: #333 !important;
}

.MuiTypography-subtitle2 {
  font-weight: 800 !important;
}

.MuiTypography-gutterBottom {
  margin-bottom: 1em !important;
}
.MuiSvgIcon-root {
  vertical-align: bottom !important;
}
.MuiTypography-body2 {
  font-size: 12px !important;
}
.how-does-it-work {
  font-weight: 700;
}
.we-shall-overcome {
  font-weight: 800;
    font-size: 14px;
    width: 260px;
    margin: 0 auto;
    display: block;
}

@media only screen and (max-width: 600px) {

  body {
    padding: 2rem;
  }

  #profile > *, #jobs > *{
    width: 39ch !important;
  }

  .logo {
    height:55px !important;
  }

  .MuiGrid-justify-xs-center {
    justify-content: flex-start !important;
    padding: 10px !important;
  }

  .header-faq > svg:nth-child(2) + svg:nth-child(0){
    display: none;
  }

  .frf-feedback-container {
    z-index: 999 !important;
  }

  /* .frf-trigger-button {
    right: -15px !important;
    bottom: 99px !important;
  } */
  .MuiTableContainer-root {
    padding-bottom: 50px !important;
  }
}



@media only screen and (min-width: 800px) {
  .frf-trigger-button {
    
    right: -23px !important; 
    bottom: 4px !important;
    height: 42px;
    
}
}

#profileURL-helper-text, #job-application-link-helper-text, .MuiFormHelperText-root {
  color: #1e1f1e;
  font-weight: 600;
}

/* .truncate {
  height: 20px;
  width: 280px; 
   padding: 0;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 0 15px 0 5px;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
} */

.faq-question {
  background-color: #eeeeee;
  padding: 10px;
  border-left: 6px solid #e60012;
}

.faq-answer {
  background-color: #fff;
  padding: 10px;
  border-left: 6px solid #9b9b9b;
}

.faq-question-container {
  display: inline;
  padding:10px;
  margin: 10px;
  color: #e60012;
  font-weight: 800;
  font-size: 12px;
}

.faq-answer-container {
  display: inline;
  padding:10px;
  margin: 10px;
  color: #9b9b9b;
}

.faq-text-container {
  display: inline;
}

.faq-container {
  margin-bottom: 50px;
}

.faq-inline {
  display: inline;
}

.header-faq > svg {
  height: 20%;
  width: 30%;
}

.faq-answer .faq-text-container {
  font-size: 13px;
}

.faq-question .faq-text-container {
  font-weight: 600;
}

.textarea {
  height: 100px !important;
}

.MuiButton-containedPrimary {
  background: #1e1f1e !important;
  padding: 15px;
}

.MuiBadge-colorPrimary {
  background: #1e1f1e !important;
}

.makeStyles-root-3 > button {
  width: 27ch !important;
  padding: 15px !important;
}