
html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Avenir Next",Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 25px
}

.MuiButton-label, .MuiFormLabel-root, .MuiTypography-body1, .MuiFormHelperText-root, .MuiTypography-h6 {
  font-family: "Avenir Next",Helvetica,Arial,sans-serif !important;
}

.MuiButton-label {
  font-weight: 800 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



body {
  margin: 0 0 20px;
  /* bottom = footer height */
}

footer {
  background-color: #333;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 44px;
  width: 100%;
  overflow: hidden;
  color: #fff;
  text-align: center;
  padding: 3px;

}

.split-bee-img {
  position: absolute;
  right: 100px;
}

.MuiGrid-spacing-xs-4 {
  width: calc(100% + 32px); 
}

.MuiButton-root {
  text-transform: none !important;
}
.MuiSvgIcon-root {
  padding: 0px 10px 0px 3px;
}

.MuiFormHelperText-root {
  color: #1e1f1e !important;
  font-weight: 600 !important;
}

.makeStyles-root-3 > button {
  width: 27ch !important;
  padding: 15px !important;
}

#resume-builder:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04);
  padding: 9px 9px 9px 0px;
  border-radius: 4px !important;
}